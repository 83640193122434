<!-- =========================================================================================
    File Name: DashboardEcommerce.vue
    Description: Dashboard - Ecommerce
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- <h4 class="header-title">Advanced Data Table</h4> -->
            <p class="text-muted font-13 mb-0"></p>
            <!-- Table -->
            <div>
              <good-table-column-search
                :moduleapi="`${this.baseApi}/purchasebill`"
                title="Purchase Bill"
                :columns="columns"
                routerpath="/inventory/addpurchasebill"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoodTableColumnSearch from '../../../components/GoodTableColumnSearch.vue'

export default {
  components: {
    GoodTableColumnSearch
  },
  data () {
    return {
      baseApi: process.env.VUE_APP_APIENDPOINT,
      columns: [
        {
          label: 'Purchase Bill No',
          field: 'purchasebillno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Purchase Bill No'
          }
        },
        {
          label: 'Name',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name'
          }
        },
        {
          label: 'Invoice No',
          field: 'invoiceno',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Invoice No'
          }
        },
        {
          label: 'Invoice Date',
          field: 'invoicedate',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Invoice Date'
          }
        },
        {
          label: 'Remarks',
          field: 'remarks',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Remarks'
          }
        },
        {
          label: 'Status',
          field: 'status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Status'
          }
        },
        {
          label: 'Amount',
          field: 'amount',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Amount'
          }
        },
        {
          label: 'Action',
          field: 'action'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
